import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap-icons/font/bootstrap-icons.css"; // Importing Bootstrap Icons

const projectsData = [
  {
    title: "MoonLightMug",
    description:
      "Calling all coffee lovers – MoonLightMug helps you find coffee shops near you, no matter where you are in the world!",
    src: "https://moonlightcoffee.vercel.app/",
    icon: "bi bi-cup-hot-fill",
  },

  {
    title: "Team Bean ",
    description:
      "Pomodoro mades simple: stay focused, Boost productivity, achieve your goals, and make every minuite count",

    src: "https://timebean.vercel.app/",
    icon: "bi bi-clock",
  },
  {
    title: "AquaTrack",
    description:
      "Easily track your water intake by telling the app how many times you drank water, and reset it for a fresh start each day.",
    src: "https://aquatracker.vercel.app/",
    icon: "bi bi-droplet-fill",
  },
  {
    title: "Care Finder",
    description:
      "Published on masslegalhelp.org, this application assists users in selecting the care type that best suits their needs.",
    src: "https://apps.suffolklitlab.org/run/care_type_finder/#/1",
    icon: "bi bi-search-heart-fill",
  },
];

const Projects = () => {
  return (
    <div>
      <div id="projects" className="project-container">
        <h3 className="fav_project_title">Fav Projects</h3>
      </div>
      <div className="container-for-projects">
        {projectsData.map((project, index) => (
          <div key={index} className={`project${index + 1}`}>
            <h1>{project.title}</h1>
            {project.icon && (
              <i className={`bi ${project.icon} project-icon`}></i>
            )}
            <p className="project_description">{project.description}</p>
            <a href={project.src} target="_blank" rel="noopener noreferrer">
              <Button className="lean-more-bttn">Learn More</Button>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
